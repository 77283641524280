export const irisCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true,
            align: 'left',
          },
          {
            prop: 'billing_entity',
            label: 'Billing Entity',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'vertical',
            label: 'Vertical',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'lang',
            label: 'Language',
            minWidth: 130,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          {
            prop: 'revenue',
            label: 'Revenue',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'cpm',
            label: 'CPM',
            minWidth: 80,
            align: 'right',
          },
          //{
            //prop: 'inter',
            //label: 'Inter',
            //minWidth: 70
          //},
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const adminCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true,
            align: 'left',
          },
          {
            prop: 'billing_entity',
            label: 'Billing Entity',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'vertical',
            label: 'Vertical',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'lang',
            label: 'Language',
            minWidth: 130,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          {
            prop: 'revenue',
            label: 'Revenue',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'cpm',
            label: 'CPM',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 90,
            align: 'right',
          },
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const adminSearch = ['date', 'billing_entity', 'site_name', 'adunit_name', 'vertical', 'lang', 'device_type', 'product_type', 'product_subtype'];

export const clientCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          {
            prop: 'revenue',
            label: 'Revenue',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const clientSearch = ['date', 'site_name', 'adunit_name', 'device_type', 'product_type', 'product_subtype'];

// Manage Users
export const manageUserCols = [
          {
            prop: 'be_name',
            label: 'Company',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'email',
            label: 'E-Mail',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'first_name',
            label: 'First Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_name',
            label: 'Last Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'is_active',
            label: 'Status',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_login',
            label: 'Last Login',
            minWidth: 110,
            align: 'right',
          },
        ];

export const manageUserSearch = ['be_name','email','first_name','last_name'];

// Activity Log
export const activityLogCols = [
          {
            prop: 'datetime',
            label: 'Datetime',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'first_name',
            label: 'First Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_name',
            label: 'Last Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'email',
            label: 'E-mail',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'query',
            label: 'Query',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 's3',
            label: 'AWS S3 Bucket',
            minWidth: 110,
            align: 'left',
          },
        ];

export const activityLogSearch = ['datetime','first_name','last_name','email'];
