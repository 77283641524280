<template>
  <div v-if="!store.irisData.user.display_tc" class="row">
    <div class="col-md-12">
      <h4 class="title">Trailing Report: {{store.irisText}}</h4>
      <p class="category irisWarning">
        * ALL NUMBERS ARE PRELIMINARY AND SUBJECT TO CHANGE.
      </p>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          Publisher Performance Table
          <button id="exportIris" v-if="queriedData.length" class="btn btn-badge btn-round btn-warning pull-right" @click="() => store.exportIris(searchQuery)">
            export csv <i class="nc-icon nc-cloud-download-93"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-iris">
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Aggregation" v-if="store.irisData">
              <el-select class="select-default iris-default"
                @change="setSummaryData()"
                  size="large"
                  v-model="store.aggregateSelected"
                  placeholder="Total">
                  <el-option v-for="option in aggregateOptions"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Rows" v-if="store.irisData">
              <el-select
                class="select-default iris-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2">

          <div class="row interRow">
            <div class="col-md-3 interCol">
              <fg-input v-if="store.irisData" class="interFG">
                <p-checkbox @click.native="toggleInter" v-model="interFlag" :key="interFlag"></p-checkbox>
              </fg-input>
            </div>
            <label class="col-md-9 col-form-label interLabel">Include International</label>
          </div>

        </div>
        <div class="col-sm-2">
          <!-- empty

          <div class="row interRow">
            <div class="col-md-3 interCol">
              <fg-input v-if="store.irisData" class="interFG">
                <p-checkbox @click.native="toggleInter" v-model="interFlag" :key="interFlag"></p-checkbox>
              </fg-input>
            </div>
            <label class="col-md-9 col-form-label interLabel">Channel Tags</label>
          </div>

          -->

        </div>
        <div class="col-sm-2">
          <!-- empty -->

        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm search-iris"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :sort-method="store.sortCustom(column.prop)"
                             :label="column.label">
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { irisCols, adminCols, adminSearch, clientCols, clientSearch } from './IrisColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    props: ['setSummaryData','aggregateOptions'],
    components: {
      PPagination
    },
    methods: {
      toggleInter() {
        this.interFlag = !this.interFlag
      },
    },
    computed: {
      tableColumns () {
        return store.tableColumns
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = store.propsToSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        let mundialData = JSON.parse(JSON.stringify(store.mundialData));
        const keyArr = Object.keys(mundialData[0]),
          keySet = new Set(keyArr),
          dimsArr = store.ogPropsToSearch ? store.ogPropsToSearch : store.propsToSearch,
          dimensionSet = new Set(dimsArr),
          metricSet = new Set([...keySet].filter(x => !dimensionSet.has(x)));

        let tableRows = [];
        switch (store.aggregateSelected) {
          case 'total':
            // Summarize
            let summaryArr = store.summarizeRows(mundialData, dimsArr, metricSet)

            // Sanitize Metrics
            tableRows = store.sanitizeMetrics(summaryArr, metricSet, true)

            if (!(store.ogTableColumns && store.ogPropsToSearch)) {
              store.ogTableColumns = JSON.parse(JSON.stringify(store.tableColumns))
              store.ogPropsToSearch = JSON.parse(JSON.stringify(store.propsToSearch))
            }

            store.tableColumns = JSON.parse(JSON.stringify(store.ogTableColumns)).slice(1)
            store.propsToSearch = JSON.parse(JSON.stringify(store.ogPropsToSearch)).slice(1)
            break;
          case 'daily':
            // Sanitize Metrics
            tableRows = store.sanitizeMetrics(mundialData, metricSet, false)

            // Set initial OG
            if (!(store.ogTableColumns && store.ogPropsToSearch)) {
              store.ogTableColumns = JSON.parse(JSON.stringify(store.tableColumns))
              store.ogPropsToSearch = JSON.parse(JSON.stringify(store.propsToSearch))
            }

            // Set Cols and Props
            store.tableColumns = JSON.parse(JSON.stringify(store.ogTableColumns))
            store.propsToSearch = JSON.parse(JSON.stringify(store.ogPropsToSearch))
            break;
          default:
            tableRows = []
        }

        // Filter INTER
        const interFlag = this.interFlag;
        if (!interFlag) tableRows = tableRows.filter(item => !item.inter)

        // Sync to export
        store.exportRows = tableRows
        return tableRows
      },

      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mundialData.length) {
          cleanRows = this.generateSummary
        }
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        interFlag: false,
      }
    },
    mounted () {
      // Integrate Roles
      // Sync export w/ filtered table
      if (store.irisData.user.is_superuser) {
        store.tableColumns = adminCols
        store.propsToSearch = adminSearch
      } else if (store.irisData.user.is_staff) {
        store.tableColumns = irisCols
        store.propsToSearch = adminSearch
      } else {
	store.tableColumns = clientCols
        store.propsToSearch = clientSearch
      }

      // Generate naming dictionary
      store.colsDict = {}
      const tableColumns = store.tableColumns;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Generate width dictionary
      //console.log({store})
    },
  }
</script>
<style>
  .interLabel {
    padding-top: 1.6em;
    padding-left: 0;
  }
  .interFG {
    text-align: right;
  }
  .interRow {
    height: 100%;
    padding-top: 1.4em;
  }
  .interCol {
    padding-right: 0;
  }
  .irisWarning {
    color: #ef8157;
  }
  #exportIris {
    color: black;
  }
  .search-iris {
    margin-top: 25px;
  }
  .body-iris {
    padding-top: 0px !important;
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
