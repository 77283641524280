<template>
  <div>

    <!-- Activity Log table -->
    <activity-log-tables v-if="store.irisData" ></activity-log-tables>

  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button } from 'src/components/UIComponents';
  import ActivityLogTables from 'src/components/Dashboard/Views/Tables/ActivityLogTables.vue'

  export default {
    components: {
      ActivityLogTables,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
      }
    },
    methods: {
    },
    async mounted () {
      store.irisText = 'Usage Data'

      //console.log({store})      
    }
  }
</script>
<style>
</style>
