<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editUser)" autocomplete="off">

        <div class="row" v-if="editValue.og_active">
          <div class="col-md-6">
            <ValidationProvider
              name="company"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'The Company field is required': null"
                :hasSuccess="passed"
                name="company"
                label="Company (required)"
                v-model="editValue.mundialprofile"
              >
                <el-select class="select-primary"
                           size="large"
                           v-model="editValue.mundialprofile"
                           placeholder="Required *">
                      <!-- <el-option v-for="option in store.mmData.be_options" -->
                      <el-option v-for="option in store.irisData.be_options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="col-md-6">
            <ValidationProvider
              name="email"
              rules="required|email|min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="email"
                      autocomplete="off"
                      :error="failed ? 'The Email field is required': null"
                      :hasSuccess="passed"
                      name="email"
                      label="Email (required)"
                      placeholder="Email"
                      v-model="editValue.email">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row" v-if="editValue.og_active">
          <div class="col-md-6">
            <ValidationProvider
              name="firstname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'First Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="firstname"
                      label="First Name"
                      placeholder="First Name"
                      v-model="editValue.first_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="lastname"
              rules="min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Last Name must be at least 1 character': null"
                      :hasSuccess="passed"
                      name="lastname"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="editValue.last_name">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row" v-if="!editValue.og_active">
          <div class="col-md-12">
            <ValidationProvider
              name="active"
              rules="required|min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input
                autocomplete="off"
                :error="failed ? 'Active field is required': null"
                :hasSuccess="passed"
                name="active"
                label="Is Active"
                v-model="editValue.is_active"
              >
                <el-select class="select-primary"
                           size="large"
                           v-model="editValue.is_active"
                           placeholder="Required *">
                      <!-- <el-option v-for="option in store.mmData.be_options" -->
                      <el-option v-for="option in [{'label':'Inactive','value':0},{'label':'Active','value':1}]"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="option.label">
                      </el-option>
                </el-select>
              </fg-input>
            </ValidationProvider>
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Edit
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleEditModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleEditUser','editValue'],
    data () {
      return {
        store,
      }
    },
    methods: {
      toggleEditModal() {
        this.editValue = {
          mundialprofile: '',
          email: '',
          first_name: '',
          last_name: '',
          is_active: '',
          og_active: '',
        }

        this.toggleEditUser()
      },
      async editUser () {
        const userDetails = JSON.parse(JSON.stringify(this.editValue));
        userDetails.username = userDetails.email

        //console.log('Your data: ' + JSON.stringify(userDetails))

        //const boolCheck = await this.store.editMundial(userDetails)
        const boolCheck = await this.store.updatePlatformUser(userDetails)
        if (boolCheck === 'EXISTING_USER') {
            alert('USER already exists!')
        } else if (boolCheck) {
            this.toggleEditUser()
        } else {
            alert('USER is incorrect!')
        }
      },
    },
    mounted () {
      //if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
      if (store.irisData.sessionFilters) store.irisData.sessionFilters = false
    },
  }

</script>
<style>

</style>
