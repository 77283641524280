var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUser)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"company","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"autocomplete":"off","error":failed ? 'The Company field is required': null,"hasSuccess":passed,"name":"company","label":"Company (required)"},model:{value:(_vm.details.irisprofile),callback:function ($$v) {_vm.$set(_vm.details, "irisprofile", $$v)},expression:"details.irisprofile"}},[_c('el-select',{staticClass:"select-primary",attrs:{"size":"large","placeholder":"Required *"},model:{value:(_vm.details.irisprofile),callback:function ($$v) {_vm.$set(_vm.details, "irisprofile", $$v)},expression:"details.irisprofile"}},_vm._l((_vm.store.irisData.be_options),function(option){return _c('el-option',{key:option.label,staticClass:"select-primary",attrs:{"value":option.value,"label":option.label}})}),1)],1)]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","autocomplete":"off","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email","label":"Email (required)","placeholder":"Email"},model:{value:(_vm.details.email),callback:function ($$v) {_vm.$set(_vm.details, "email", $$v)},expression:"details.email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","autocomplete":"off","error":failed ? 'First Name must be at least 2 characters': null,"hasSuccess":passed,"name":"firstname","label":"First Name","placeholder":"First Name"},model:{value:(_vm.details.first_name),callback:function ($$v) {_vm.$set(_vm.details, "first_name", $$v)},expression:"details.first_name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"lastname","rules":"min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","autocomplete":"off","error":failed ? 'Last Name must be at least 1 character': null,"hasSuccess":passed,"name":"lastname","label":"Last Name","placeholder":"Last Name"},model:{value:(_vm.details.last_name),callback:function ($$v) {_vm.$set(_vm.details, "last_name", $$v)},expression:"details.last_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-info btn-fill btn-wd",attrs:{"type":"submit"}},[_vm._v(" Create ")]),_c('button',{staticClass:"btn btn-default btn-fill btn-wd",attrs:{"type":"button"},on:{"click":_vm.toggleCreateModal}},[_vm._v(" Cancel ")])]),_c('div',{staticClass:"clearfix"})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }